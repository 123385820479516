<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    :show-close="false"
    custom-class="pm-custom-common-dialog"
    :close-on-click-modal="closeOnClickModal"
  >
    <!-- title -->
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <!-- body -->
    <template>
      <div class="close-btn" @click="handleClose">
        <i class="pm-iconfont pm-icon-guanbi"></i>
      </div>
      <slot>
        <div>dialog content slot</div>
      </slot>
    </template>
    <!-- footer -->
    <template slot="footer" v-if="showFooter">
      <slot name="footer">
        <span class="dialog-footer">
          <el-button @click="handleClose" round class="cancel-btn"
            >取 消</el-button
          >
          <el-button
            round
            type="primary"
            @click="handleConfirm"
            class="confirm-btn"
            >确 定</el-button
          >
        </span>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    width: {
      type: String,
      default: () => "30%",
    },
    closeOnClickModal: {
      type: Boolean,
      default: () => false,
    },
    showFooter: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.$emit("confirm", this.handleClose);
    },
  },
};
</script>

<style lang="scss" scoped>
.pm-custom-common-dialog {
  .close-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -40px;
    color: #cccccc;
    i {
      font-size: 30px;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .cancel-btn,
    .confirm-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 32px;
      border-radius: 16px;
      &:hover {
        opacity: 0.85;
      }
    }
    .cancel-btn {
      background: linear-gradient(
        90deg,
        rgba(73, 167, 255, 0.1) 0%,
        rgba(71, 119, 255, 0.1) 100%
      );
      color: var(--pm-main-menu-active-color);
      border-color: transparent;
    }
    .confirm-btn {
      background: linear-gradient(56deg, #3a8aff 16.47%, #49a7ff 83.18%);
    }
  }
}
</style>
