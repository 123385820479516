<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { DynamicRoutesMenus, MY_SETTINGS } from "@/constants/storageKey"
import { getToken, setUserInfo, getDynamicRoutesMenus } from "@/utils/auth";
import { getUserInfo } from "@/api/user";
import { handleFirstJump } from "@/utils/jump";
import { mapActions } from "vuex";

let socket = "";
let path = "wss://pm.yichenghbkj.com/websocketReg";
// let path = "ws://pm.yichenghbkj.com:18516/websocketReg";
let lockReconnect = false;
//心跳检测
var heartCheck = {
  timeout: 1000 * 5, //s发一次心跳
  timeoutObj: null,
  serverTimeoutObj: null,
  reset: function () {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    return this;
  },
  start: function () {
    var self = this;
    this.timeoutObj = setTimeout(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      if (socket.readyState == 1) {
        socket.send("ping");
      }
      self.serverTimeoutObj = setTimeout(function () {
        //如果超过一定时间还没重置，说明后端主动断开了
        socket.close(); //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, self.timeout);
    }, this.timeout);
  },
};

export default {
  name: "App",
  methods: {
    ...mapActions("permission", ["queryDynamicRoutes"]),
    initSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        if (
          !this.$root.ws ||
          (this.$root.ws && this.$root.ws.readyState == 3)
        ) {
          console.log("socket初始中...");
          // 实例化socket
          socket = new WebSocket(path);
          // 监听socket连接
          socket.onopen = this.open;
          // 监听socket错误信息
          socket.onerror = this.error;
          // 监听socket消息
          socket.onmessage = this.getMessage;
          socket.onclose = this.close;
          this.$root.ws = socket;
        } else {
          console.log("socket已连接...");
          socket = this.$root.ws;
          console.log("socket.readyState...", socket.readyState);
        }
      }
    },
    reconnect() {
      if (lockReconnect) return;
      lockReconnect = true;
      setTimeout(() => {
        this.initSocket();
        lockReconnect = false;
      }, 2000);
    },
    open() {
      heartCheck.reset().start();
      console.log("%csocket连接成功 ==========>", "color: green;");
      this.getNoticeList();
    },
    error(err) {
      console.log(`%csocket连接错误 ==========> ${err}`, "color: red;");
      this.reconnect();
    },
    getMessage(msg) {
      heartCheck.reset().start();
      if (msg.data == "pong") {
        return;
      }
      console.log("ws data...", msg.data);
      let data = JSON.parse(msg.data);
      this.messageData = data;
      this.send();
      switch (data.cmd) {
        case "refresh":
          this.getNoticeList();
          break;
        case "menu_refresh":
          this.handleMenuRefresh();
          break;
        case "user_refresh":
          this.Pleasecache();
          break;
        case "refresh_announcement":
          this.getAfficheList();
          break;
        default:
          break;
      }
    },
    /**
     * scoket修改菜单，跳转到第一个
     */
    async handleMenuRefresh() {
      sessionStorage.removeItem(DynamicRoutesMenus);
      sessionStorage.removeItem(MY_SETTINGS);
      this.$router.push({
        path: "/index",
      });
      window.location.reload();
      this.$message.warning("菜单权限发生变更");
    },
    getNoticeList() {
      this.$eventHub.$emit("getNoticeList", null);
    },
    // 通知公告组件刷新列表
    getAfficheList() {
      this.$eventHub.$emit("getAfficheList", null);
    },
    send() {
      if (this.messageData.sole_id) {
        let params = `{"type":"register","token":"${getToken()}","sole_id":"${
          this.messageData.sole_id
        }"}`;
        socket.send(params);
        console.log("%c发送成功！！！！ ==========>", "color: #e9ce0f;");
      }
    },
    close() {
      this.reconnect();
      console.log("%csocket已经关闭 ==========>", "color: #ccc;");
    },
    Pleasecache() {
      getUserInfo().then((res) => {
        setUserInfo(res.data);
        sessionStorage.removeItem(DynamicRoutesMenus);
        sessionStorage.removeItem(MY_SETTINGS);
        location.reload();
      });
    },
    convertStyle() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  mounted() {
    // 如果有登录缓存，且未输入详细地址，或者当前页面权限被取消，则跳转到第一个路由
    if (window.location.hash === "#/") {
      handleFirstJump();
    }
    setTimeout(() => {
      this.initSocket();
    }, 1000);

    window.addEventListener("resize", this.convertStyle);
    window.addEventListener("DOMContentLoaded", this.convertStyle);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.convertStyle);
    window.removeEventListener("DOMContentLoaded", this.convertStyle);
  }
};

const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
</script>

<style lang="scss">
@import "./assets/font/font.css";
#app {
  font-family: "HarmonyOS_Sans_SC";
  font-weight: bold;
  /* border: 1px solid #B7C2D2; */
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "HarmonyOS_Sans_SC" !important;
  // font-weight: 500;
  /* font-weight: bold; */
}
</style>
